import React, {useCallback, useContext, useState} from "react";
import PropTypes from "prop-types";
import {graphql, useStaticQuery} from "gatsby";
import RouterRootContext from "../../plugins/idsign-router/routerRootContext";
import { getImage } from "gatsby-plugin-image";
import find from "lodash/find"

const MainContext = React.createContext();

const MainProvider = ({children, pageContext}) => {
    const gatsbyQuery = useStaticQuery(graphql`
    query {
      allTranslations {
        edges {
          node {
            lang
            messages {
              key
              message
            }
          }
        }
      }
      allRoutes {
        edges {
          node {
            route
            options {
              en {
                path
              }
              it {
                path
              }
              fr {
                path
              }
            }
          }
        }
      }
    }
  `);
    const getImageFromName = (images, name) => {
        const image =
            find(images || [], (img) => {
                return img.node.base === name;
            }) || {};

        if (!image) {
            return null;
        }
        return getImage(image.node);
    };
    const rContext = useContext(RouterRootContext);
    const currentLang = useCallback(() => pageContext.locale || rContext?.router?.getDefaultLanguage(), [ rContext,pageContext]);
    const translate = (key, params = null, locale = '', defaultValue = '') => {
        locale = locale || currentLang();
        return rContext.translator.translate(gatsbyQuery?.allTranslations?.edges || [], locale, key, params, defaultValue, rContext?.router?.getDefaultLanguage())
    }
    const isDev = () => {
        return process.env.GATSBY_ENV === 'local'
    }
    const url = useCallback((to, lang = '') => {
        lang = lang ||  currentLang();
        return rContext.router.getUrl(gatsbyQuery?.allRoutes?.edges || [], to, lang)
    }, [rContext, gatsbyQuery, currentLang])
    const [initial, setInitial] = useState({opacity: 0,  scale: 1,});
    const [animate, setAnimate] = useState({opacity: 1, scale: 1, transition: {duration: 0,}});
    const [exit, setExit] = useState({opacity: 0,  scale: 1,transition: {delay: 0, duration: 0},});

    const noAnimation = () => {
        setInitial({opacity: 1, scale: 1,})
        setAnimate({opacity: 1, scale: 1, transition: {duration: 0,}})
        setExit({opacity: 1,  scale: 1,transition: {delay: 0, duration: 0},})
    }
    const defaultAnimation = () => {
        setInitial({opacity: 0 ,scale: 1,})
        setAnimate({opacity: 1, scale: 1, transition: {duration: 0,}})
        setExit({opacity: 0,  scale: 1,transition: {delay: 0, duration: 0},})
    }
    const scaleAnimation = () => {
        setInitial({opacity: 0, scale: 0.90})
        setAnimate({opacity: 1, scale: 1, transition: {duration: 0.3,opacity: {
                    duration: 0.8
                }}})
        setExit({opacity: 1, scale: 1, transition: {delay: 0, duration: 0},})
    }
    const fadeOutProjectAnimation = () => {
        setInitial({opacity: 0,  scale: 1,})
        setAnimate({opacity: 1,  scale: 1,transition: {duration: 0,}})
        setExit({opacity: 1,  scale: 1, transition: {delay: 1, duration: 3},})
    }


    const [privacy,] = useState({
        it: 48975812,
        fr: 10381537,
        en: 80077679
    });
    return (
        <MainContext.Provider
            value={{
                getImageFromName,
                translate,
                privacy,
                languages: rContext?.router?.getLanguages(),
                url,
                isDev,
                // cs: rContext.cs,
                singleLang: !rContext?.router?.multiLanguage(),
                animation: {
                    initial,
                    setInitial,
                    animate,
                    setAnimate,
                    exit,
                    setExit,
                    noAnimation,
                    defaultAnimation,
                    scaleAnimation,
                    fadeOutProjectAnimation
                }
            }}
        >
           <>
               {children}
           </>
        </MainContext.Provider>
    );
};
MainContext.propTypes = {
    pageContext: PropTypes.object,
};
export default MainContext;
export {MainProvider};
