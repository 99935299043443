import React, {useContext} from "react";
import PropTypes from "prop-types";
import RouterRootContext from "./routerRootContext";

const RouterPageContext = React.createContext();

const RouterPageProvider = ({children, pageContext}) => {
    const rContext = useContext(RouterRootContext);
    const isCurrentRoute = (name, lang) => {
        return pageContext.route === name && (lang ? (pageContext.locale === lang) : true)
    }

    return (
        <RouterPageContext.Provider
            value={{
                router: rContext.router,
                translator: rContext.translator,
                isCurrentRoute,
                locale: pageContext.locale || "",
                locales: rContext?.router?.getLanguages(),
            }}
        >
            {children}
        </RouterPageContext.Provider>
    );
};
RouterPageContext.propTypes = {
    pageContext: PropTypes.object,
};
export default RouterPageContext;
export {RouterPageProvider};
