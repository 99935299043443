const map = require("lodash/map")
const each = require("lodash/each")
const path = require("path");
const find = require("lodash/find");
const get = require("lodash/get");

module.exports = class Translator {
    constructor(config = '', languages = [], fallbackLocale = 'it') {
        this.translations = {};
        this.messages = {};
        this.fallback = fallbackLocale
        this.languages = languages
        this.config = config
    }

    loadTranslations() {
        const messages = {};
        each(this.languages, (lang) => {
            messages[lang] = this.mergeLangs(lang);
        })

        this.setTranslations(messages);
        this.setCurrent(this.fallback)
    }

    setCurrent(lang) {
        this.locale = lang;
        this.messages = this.translations[this.locale || this.fallback] || {};
        return this.getCurrentMessages();
    }

    getCurrentMessages() {
        return this.messages;
    }

    setTranslations(translations) {
        this.translations = translations;
    }

    getTranslations() {
        return this.translations;
    }

    mergeLangs(lang) {
        return require(path.resolve(this.config.path, lang, this.config.file));
    }

    getGraphQl(lang) {
        return map(this.translations[lang] || {}, (t, i) => {
            return {
                key: i,
                message: t
            }
        })
    }


    fParams (str, params) {
        each(params, (value, key) => {
            str = str.replace(`{{${key}}}`, value);
        });
        return str;
    }
    translate (graphqlTranslations, lang, key, params = null, defaultValue = '', fallback = '') {
        const selectedLang = find(
            graphqlTranslations,
            (r) => r.node.lang === lang
        ) || {node: {}};

        const message = find(
            selectedLang.node.messages,
            (m) => m.key === key
        ) || {};
        const translated = get(message, `message`, false);
        if (!translated) {
            return (defaultValue || key) + ("_"+(lang.toUpperCase())+"_");
        }
        return params ? this.fParams(translated, params) : translated;
    }

};
