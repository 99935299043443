const path = require("path");
const indexOf = require("lodash/indexOf")
const map = require("lodash/map")
const get = require("lodash/get")
const each = require("lodash/each");
const isEmpty = require("lodash/isEmpty");
const find = require("lodash/find");
module.exports = class Router {
    constructor(config = '',languages = ['it'], fallbackLocale = 'it') {
        this.languages = languages
        this.fallbackLocale = indexOf(this.languages, fallbackLocale) !== -1 ? fallbackLocale : 'it'
        this.current = {}
        this.config = config
    }

    initRoutes () {
        this.routes = require(path.resolve(this.config.path, this.config.file))
        return this.routes
    }

    getDefaultLanguage(ipLang= '') {
        if (ipLang && indexOf(this.getLanguages(), ipLang) !== -1) {
            return ipLang
        }

        if (typeof navigator === `undefined`) {
            return this.getFallbackLocale();
        }

        const lang = navigator && navigator.language && navigator.language.split("-")[0];
        if (!lang) {
            return this.getFallbackLocale();
        }

        if (indexOf(this.getLanguages(), lang) === -1) {
            return this.getFallbackLocale();
        }

        return lang
    }

    setCurrent(route) {
        this.current = route
    }

    getCurrent() {
        return this.current
    }

    getFallbackLocale() {
        return this.fallbackLocale
    }

    getLanguages() {
        return this.languages
    }

    getRoutes() {
        return this.routes
    }


    url(key, lang = null, log = false) {
        lang = lang || this.getFallbackLocale()
        const singleLang = this.getLanguages().length <= 1;
        if (key === 'index') {
            return singleLang ? `/` : `/${lang}/`
        }
        const p = get(this.routes, `${key}.${lang}.path`, false)

        if (!p) {
            return '#'
        }
        return singleLang ? `/${p}/` : `/${lang}/${p}/`
    }

    addRoute(name, info, component = null) {
        this.routes[name] = info
        if (component) {
            this.routes[name].component = component
        }
    }

    cleanRoute(route) {
        return route.toLowerCase().replace(/ *\([^)]*\) */g, "").replace(/\s/g, '')
    }

    getRouteNodes() {
        return map(this.routes, (options, name) => {
            return {
                route: name,
                options: {
                    ...options,
                    component: options.component || name
                }
            }
        })
    }

    createPage (domain, routes, createPageInstance) {
        each(routes, (r2) => {
            const r = r2.node;
            const name = r.route;
            const route = r.options || {};
            if (isEmpty(route)) {
                console.log(`Empty route with name ${name}`);
            }
            const context = route.context || {};
            const component = route.component || name;

            const siteLanguages = this.getLanguages();
            each(siteLanguages, (lang) => {
                const page = route[lang] || {};
                let pagePath = this.url(name, lang);
                const getIsoLocale = (locale) => locale === "en" ? "en_US" : `${locale}_${locale.toUpperCase()}`;

                if (!isEmpty(page)) {
                    let langAlternates = [];
                    let localeIsoAlternates = [];
                    let canonicalUrl = `${domain || ''}` + pagePath;
                    siteLanguages.forEach((routeLocale) => {
                        langAlternates.push({
                            hrefLang: routeLocale,
                            href: `${domain || ''}` + this.url(name, routeLocale),
                        });
                        if (routeLocale === lang) {
                            return;
                        }
                        localeIsoAlternates.push(getIsoLocale(routeLocale));
                    });
                    delete page.path
                    createPageInstance({
                        path: pagePath,
                        component: path.resolve(this.config.pages, `${component}.js`),
                        context: {
                            ...context,
                            ...page,
                            route: name,
                            locale: lang,
                            langAlternates,
                            canonicalUrl,
                            localeIsoCode: getIsoLocale(lang),
                            localeIsoAlternates,
                            domain: domain,
                        },
                    });
                } else {
                    console.log("createPage route error: ", name);
                }
            });
        });
    }

    multiLanguage () {
        return this.languages.length > 1;
    }

    getUrl (graphqlRoutes, to, lang) {
        const key = (to || '').toLowerCase();
        if (key === "index") {
            return !this.multiLanguage() ? '/' : `/${lang}/`;
        }
        const route = find(
            graphqlRoutes,
            (r) => r.node.route === key
        ) || {node: {}};
        const path = get(route, `node.options.${lang}.path`, false);

        if (!path) {
            return "#";
        } else if (path.slice(-1, 1) !== "/") {
            return !this.multiLanguage() ? `/${path}/` : `/${lang}/${path}/`;
        } else {
            return !this.multiLanguage() ? `/${path}` : `/${lang}/${path}`;
        }
    }
}
