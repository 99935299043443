import React from 'react';

import "@fontsource/nunito/200.css";
import "@fontsource/nunito/300.css";
import "@fontsource/nunito/400.css";
import "@fontsource/nunito/500.css";
import "@fontsource/nunito/600.css";
import "@fontsource/nunito/700.css";
import "@fontsource/nunito/800.css";
import "@fontsource/nunito/900.css";

import './src/assets/scss/app.scss'
import {RouterPageProvider} from "./plugins/idsign-router/routerPageContext";
import {MainProvider} from "./src/context/MainContext";

export const wrapPageElement = ({element, props}) => (<RouterPageProvider  {...props}>
    <MainProvider  {...props}>
        {element}
    </MainProvider>
</RouterPageProvider>);
