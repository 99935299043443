module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/images/icon.png","cache_busting_mode":"name","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c77a4e208c0cbe936a5d7fed4b6a4451"},
    },{
      plugin: require('../plugins/idsign-router/gatsby-browser.js'),
      options: {"plugins":[],"languages":["it","en","fr"],"defaultLanguage":"it","translations":{"path":"./src/translations","file":"index.js"},"routes":{"path":"./src/routes","pages":"./src/templates","file":"index.js"},"domain":"https://computerteam.it","graphql":"\n                    query {\n                      allTranslations {\n                        edges {\n                          node {\n                            lang\n                            messages {\n                              key\n                              message\n                            }\n                          }\n                        }\n                      }\n                      allRoutes {\n                        edges {\n                          node {\n                            id\n                            route\n                            options {\n                              component\n                              context {\n                                extra\n                              }\n                              it{\n                                path\n                                title\n                                description\n                              }\n                              en{\n                                path\n                                title\n                                description\n                              }\n                              fr{\n                                path\n                                title\n                                description\n                              }\n                            }\n                          }\n                         }\n                      }\n                    }\n                "},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
