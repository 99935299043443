import React from "react";
import PropTypes from "prop-types";
const RouterRootContext = React.createContext();

const RouterRootProvider = ({children, router, translator, options}) => {

    //const cs = typeof window !== 'undefined' ? window.initCookieConsent() : {}

    return (

        <RouterRootContext.Provider
            value={{
                router,
                translator,
                options,
                //cs
            }}
        >
            {children}
        </RouterRootContext.Provider>
    );
};
RouterRootContext.propTypes = {
    pageContext: PropTypes.object,
};
export default RouterRootContext;
export {RouterRootProvider};
