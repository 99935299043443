import React from "react";
import {RouterRootProvider} from "./routerRootContext";
import Router from "./router";
import Translator from "./translator";

export const wrapRootElement = ({element, props}, pluginOptions) => {
    const router = new Router(pluginOptions.routes, pluginOptions.languages, pluginOptions.defaultLanguage);
    const translator = new Translator(pluginOptions.translations, pluginOptions.languages, pluginOptions.defaultLanguage);
    return <RouterRootProvider options={pluginOptions} router={router} translator={translator} >
        {element}
    </RouterRootProvider>;
};
